import { createApp } from "vue";
import Vue from "vue"
import App from "./App.vue";
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
//imports for app initialization
// import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);
import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";
import VueApexCharts from "vue3-apexcharts";
import PrimeVue from 'primevue/config';
// import './resources/themes/saga-blue/theme.css';  // or any other theme you prefer
// import './resources/primevue.min.css';

import Noir from './presets/Noir.js';
import AppState from './plugins/appState.js';




const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(PrimeVue,
    {
        theme: {
            preset: Noir,
            options: {
                prefix: 'p',
                darkModeSelector: 'system',
                cssLayer: false
            }
        }
    }
);
app.use(AppState);
ApiService.init(app);

// MockAdapter.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.mount("#app");
